const resources = {
  data () {
    return {
      resources: {
        HOTEL: 1,
        HOTEL_ROOM: 2,
        CRUISE: 3,
        CABIN: 4,
        TOUR: 5,
        RESORT: 6,
        HOTEL_TOUR: 7,
        HEALING_FACTOR: 8,
        READINGS: 9,
        TREATMENTS: 10,
        PROCEDURE: 11,
        CONTRAINDICATIONS: 12,
        HOTEL_READINGS: 13,
        HOTEL_TREATMENTS: 14,
        HOTEL_CONTRAINDICATIONS: 15,
      },
    };
  },
};

export default resources;
